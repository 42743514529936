.out {
	display: flex;
	flex-direction: column;
	min-height: 100vh; }

main {
	flex-grow: 1; }

::selection {
	background: $yellow; }

.skip {
	padding: 1em .5em;
	text-align: center;
	border-radius: 3px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	background-color: $heatGreen;
	transform: translateX(-110%);
	will-change: transform;
	z-index: 10000;
	color: $white;
	top: 100px;
	position: fixed;

	&:focus {
		transform: translateX(0);
		color: $white;
		outline: 0; } }

.curtain, .preloader {
	position: fixed;
	will-change: opacity;
	z-index: 10; }

.curtain {
	background-color: $purple;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.preloader {
	top: 40%;
	left: 47.5%;
	width: 10%; }

.not-follow {
	@include after;
	z-index: 0;
	@extend %relative;

	&:after {
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="rgb(157, 91, 75)" d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z§" /></svg>');
		background-size: 1em;
		background-repeat: no-repeat;
		position: static;
		display: inline-block;
		margin-left: .25em;
		transform: translateY(.2em);
		height: 1em;
		right: 0;
		width: 1em; } }


.article-preview {
	display: grid;
	grid-template-columns: 30% 1fr;
	grid-template-rows: auto 1fr;
	grid-column-gap: 24px;

	&__title {
		grid-column-start: 2;
		grid-row-start: 1; }

	&__image {
		&.article__image {
			margin: 0; }

		align-self: center;
		grid-row: span 2;
		max-width: 100%;
		order: -1;
		min-height: 200px;
		display: block;
		z-index: 2;
		position: relative;

		&::after {
			background-image: linear-gradient(to right, white, #dadada, white);
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			animation: background-position-skeleton infinite 1s ease-in-out;
			width: 100%;
			height: 100%;
			display: block; } } }


@keyframes background-position-skeleton {
	0% {
		background-position: 0; }

	100% {
		background-position: 342px 0; } }

.article-preview-meta {
	grid-row-start: 2;
	grid-column-start: 2; }

.previous, .next {
	text-decoration: none; }

.work__refer, .previous, .next {
	&:hover {
		text-decoration: none; } }

.previous, .next {
	transition: 0.2s ease-in-out;
	will-change: transform, box-shadow;

	&:hover {
		box-shadow: 0 10px 20px $shadow;
		transform: scale(1.01, 1.01); } }

.desktop-works {
	display: flex;
	margin: 0 -9px; }

.header {
	display: flex;
	flex-direction: column;
	position: relative;

	&_with-about-section {
		height: calc(100vh - 80px);

		@media (max-height: 750px) {
			height: auto; }

		.aboutMeInHeader::before {
			content: '';
			position: absolute;
			width: 100vw;
			bottom: 0;
			left: 50%;
			background-color: rgba($darkGray, .3);
			background: linear-gradient(to right, rgba($darkGray, .5), rgba($darkGray, .5) 100%), url(/static/portfolio/img/afinskaya-shkola.jpeg);
			background-position: left bottom;
			background-repeat: no-repeat;
			background-size: cover;
			background-blend-mode: darken;
			height: 100%;
			transform: translateX(-50%); } } }

.aboutMeInHeader {
	padding-top: 36px;
	padding-bottom: 54px;
	flex: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@include breakpoint($xs) {
		padding-bottom: 36px; }

	@include breakpoint($xxs) {
		font-size: 2.2vh; }

	.about > * {
		margin: 0;
		color: #fff; }

	.about {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		&:last-child {
			margin-bottom: 0; } } }

.footer {
	border-style: solid;
	border-color: lighten($gray, 50%); }

.v-centered,
.brand,
.contacts,
.worksPagination,
.main > .container {
	align-items: center; }

.parent {
	display: flex; }

.column {
	flex-direction: column; }

.head, .brand, .contacts, .worksPagination {
	flex-flow: row wrap; }

.brand, .contacts {

	@include breakpoint($xs) {
		width: span(12);
		justify-content: center; } }

.child {
	flex: 1 0 20%; }

.head, .worksPagination {
	justify-content: space-between; }

.works {
	display: flex;
	flex-direction: column;
	padding: 0 9px;
	margin: 0;

	@include breakpoint($xs-up) {
		width: 33%; } }

.currentWork {
	flex-wrap: nowrap $i;
	flex-direction: column $i;
	margin: 0 auto;

	@include breakpoint($md) {
		width: span(6); }

	@include breakpoint($sm-less) {
		width: span(12); }

	&__refer {
		white-space: nowrap;
		overflow: hidden;
		display: block;
		text-overflow: ellipsis;

		@include breakpoint($xs-up) {
			font-size: (9em / 16);
			max-width: 345px; }

		@include breakpoint($xs) {
			font-size: .5em;
			max-width: calc(100vw - 2em); } } }

.head {
	background-color: $lightGray;
	padding: 0.5em 3.8em;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

	@include breakpoint($xs) {
		padding: 1em 2em 2em; } }

@mixin b($media) {
	@include breakpoint($media) {
		@content; } }


.previous,
.next,
.currentWork {
	width: span(4); }

.previous,
.next {
	padding: 0.2em 0.5em;
	color: $blue;
	background-image: none;

	@include breakpoint($md) {
		width: span(3); }

	@include breakpoint($sm-less) {
		width: span(6);
		padding-top: 0.5em; }

	@include breakpoint($xxs) {
		width: span(12);
		display: flex;
		align-items: center;
		flex-direction: column; } }

.previous {
	@include breakpoint($sm-less) {
		order: 2;
		text-align: right; } }
.next {
	@include breakpoint($sm-less) {
		text-align: left; } }

.work__refer {
	color: $darkGray;
	display: block;
	background-image: none;
	padding: 0;

	&:hover, &:focus {
		background-image: none; } }

.work {
	margin: 0 0 18px;
	padding: 0 1em 18px;
	will-change: transform, box-shadow;
	box-shadow: 0 5px 20px rgba(0,0,0,.3);

	@include breakpoint($xs) {
		width: 100vw; }

	&__image {
		min-width: calc(100% + 2em);
		margin-left: -1em;
		margin-right: -1em; }

	&__title {
		margin-top: 9px;
		margin-bottom: 18px;
		word-wrap: break-word; } }

.tags {
	margin-top: 0;

	.label {
		margin-bottom: 9px;
		margin-top: 0; } }

.brandImage {
	max-width: 69px;
	width: span(3);
	@include round;
	overflow: hidden;
	background-color: $blue;

	&__image {
		@include round;
		padding-bottom: (2491% / 2304 * 100);
		@include cover;
		width: 74px;
		will-change: transform;
		transition: transform 100ms linear;
		display: block;

		&:hover {
			transform: scale(1.618); } } }

.head-gitlab {
	flex-shrink: 0;
	align-self: center;
	margin-left: auto;
	margin-right: auto;

	&__icon {
		width: 52px; } }

.brandImage {
	margin-right: 1em; }

.font-weight {

		&_semi {
			font-weight: bold; } }

.brand__text {
	margin-bottom: 0;
	font-weight: normal;
	font-size: 16px;

	@include breakpoint($xxs) {
		margin-top: 9px; } }

.brand__title {
	color: $darkGray;

	@include breakpoint($xs-up) {
		position: relative;
		top: -0.2em; }

	@include breakpoint($xs) {
		margin-top: .2em;
		text-align: center; }

	@include breakpoint($xxs) {
		line-height: 1.2; } }


.navigation {
	background: $white;
	min-height: 80px;
	justify-content: center;
	transition: transform 250ms ease-in-out;
	display: flex;
	top: 0;
	left: 0;
	z-index: 1000;
	box-shadow: 0 2px 20px rgba($blue, 0.3);
	position: sticky;

	&_down {
		transform: translateY(-100%); } }

.navListItem {
	height: 80px;
	white-space: nowrap; }

.navList {
	margin: 0;
	overflow: auto; }

.navListItem__refer {
	height: 100%;
	padding: 0 36px;
	display: flex;
	align-items: center;
	background: none;
	color: $darkGray;
	transition-property: background-color, color;
	transition-duration: 200ms;
	transition-timing-function: ease-in;

	&:hover, &:focus {
		background-color: $blue;
		color: $white;
		background-image: none; } }

.brand {
	&, &:hover {
		background-image: none; } }

.contacts {
	color: $darkenGray;
	justify-content: flex-end;

	@include breakpoint($xs) {
		font-size: 18px; } }

.footer {
		color: $white;
		background-color: $darkGray;
		margin: 1em 0 0; }

.withoutUnderline {
	&, &:hover, &:focus {
		background-image: none; } }

.contactIcon {
	min-width: 36px;

	svg {
		width: 36px;
		transition-duration: 250ms;

		&:hover {
			color: $blue; } } }

.margin {
	&-right {

		&_offset {
			margin-right: 3rem; } }

	&-left {

		&_auto {
			margin-left: auto; } } }


.presentWork {
	width: 100%;
	grid-gap: 16px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	@include breakpoint($xs) {
		grid-template-columns: 1fr;
		min-width: 100%; } }

.presentWorkItem {
	display: flex;
	align-items: center;

	* {
		width: 100%; }

	a {
		background-image: none; } }

.footerContacts {
	padding: 1.25em 0 1em;

	a {
		color: $yellow; } }


.label {
	font-size: 1em;
	display: inline-block;
	margin-right: 0.5em; }

.main {
	padding-top: 1.5em;
	content-visibility: auto; }

.main, .head {
	@include breakpoint($xs) {
		font-size: +em(18); } }

.main > .container {
	flex-direction: column;
	justify-content: center; }

.main p {
	max-width: 75rem; }

.worksPagination {
	width: 100%; }

.materialShadow {
	box-shadow: 0 2px 6px 0 rgba(0,0,0,.5); }

.task {
	background-color: $blue;
	box-shadow: 0 4px 20px rgba($blue, .4);
	padding: 0 1em 1.3em;
	border-radius: 4px;
	max-width: 60ch;
	margin-top: 18px;
	margin-bottom: 27px;
	color: $white; }

.task__title {
	border-style: solid;
	border-bottom-width: 1px;
	border-image: linear-gradient(to right, $blue 0%, $blue 50%, transparent 50%) 1;
	padding-bottom: 0.2em; }

.task__paragraph {
	font-size: (14em / 16); }

.tabLink {
	background-color: $white;

	&:hover, &:focus {
		z-index: 2; } }


.about {
	max-width: 75rem;
	width: 100%;

	&:last-child {
		margin-bottom: 1em; } }

.about, .article {
	&:not(:first-child) {
		margin-top: 1.5em; } }
// End about

// Start article
.articleSection {
	&:first-child {
		margin-top: 1.5em; }
	margin-top: .5em;
	padding: 1em 0;
	&:not(:last-child) {
		border-bottom: 1px solid lighten($gray, 50%); } }

// End article

.text-uppercase {
	letter-spacing: .12em; }

.technologies, .technologiesImageContainer {
	width: 100%; }

.imageContainer__image {
		background: no-repeat center;
		background-size: cover; }

.centered {
	justify-content: center;
	align-items: center; }

.technologiesList {
	justify-content: center;
	margin-top: 1em;
	margin-bottom: 1em;
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: repeat(auto-fit, 90px);
	grid-gap: 27px 18px; }

.technology, .imageContainer_gear {
	margin: auto .5em;
	flex-basis: 33%; }

.technology {
	min-width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
	margin: 0;
	padding: .25em .5em .5em;
	border: 3px solid $darkGray;
	box-shadow: 3px 3px 0 rgba($blue,.4);

	&__image {
		width: 100%;

		&[src*="gulp"] {
			max-width: 35px; } } }



.technologiesTitle {
	align-items: center;
	display: flex;

	@include breakpoint($sm-less) {
		flex-direction: column; }
	@include breakpoint($xxs) {
		font-size: 7vw; } }

.imageContainer_gear {
	max-width: em(65, 36);

	@include breakpoint($sm-less) {
		width: span(12);
		margin: .5em 0; } }

.imageContainer__image_gear {
	padding-bottom: pb(2000, 1969);
	@include image('/icons/gear.png'); }

.controller {
	flex-basis: span(12);

	&:not(:first-child) {
		margin-top: 1em; }

	&_required {
		color: $purple; }

	&__icon {
		margin-bottom: .15em; }

	&__input {
		min-width: span(12);
		max-width: span(12);
		font-size: em(18, 21);
		padding: 18px;
		transition-duration: 250ms;
		box-shadow: 3px 3px 0px rgba(0,0,0,.5);
		border: 3px solid #333;
		border-bottom-color: $purple;
		margin-top: 0;
		will-change: border;

		&, &_textinput {
			border-color: #ccc;

			&:focus {
				&:invalid {
					border-color: $purple; }
				&:valid {
					border-color: $blue; } } }

		&_textinput {
			max-height: 8.5em; }

		&_select {
			padding: .5em;
			margin-top: .5em; } }

	&__label {
		align-self: flex-end;
		margin-left: -3px; }

	&__checkbox {
		-webkit-appearance: none;
		align-self: center;
		cursor: pointer;
		height: em(26);
		width: em(26);
		border: 2px solid $purple;
		background-color: $gray;
		max-width: span(1);
		margin-right: span(1) / 2;
		display: inline-block;
		position: relative;

		&:checked:after {
			content: '\2714';
			position: absolute;
			top: em(1);
			left: em(3);
			color: $darkGray; } }


	&__checkboxLabel {
		color: $white;
		padding: .5em 0 .8em 1em;
		max-width: span(10);
		background-color: $red; } }

.formWrapper {
	max-width: em(350);
	margin: 0 auto 1em; }

.formLoader {
	max-width: em(50);
	margin: 3em auto;
	display: block; }

.button {
	border: 3px solid $darkGray;
	width: 100%;
	max-width: em(125);
	color: $white;
	padding: 18px 9px;
	will-change: transform;
	transition: transform .3s ease-in;
	box-shadow: 3px 3px 0 rgba(0,0,0,.5);

	&:hover, &:focus {
		transform: scale(1.04); }

	&:active {
		transform: translateY(4px); }

	&_purple {
		background-color: $purple; }

	&_yellow {
		background-color: $yellow;
		color: $darkGray; } }

.articles {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 16px 24px; }

.articlesLoader {
	max-width: em(45);
	margin: 1em auto;
	width: span(12);
	display: block;
	align-self: center; }

.connectForm {

	&__button {
		margin-top: 1.5em; } }
